import WarehouseAttributes from '@albatrosdeveloper/ave-models-npm/lib/schemas/warehouse/warehouse.entity';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/core/service/auth.service';
import { WarehouseWithTotal } from 'src/app/modules/warehouse/warehouse.model';
import { ViewImageComponent } from 'src/app/shared/view-image/view-image.component';

import { Courier } from 'src/app/modules/warehouse/courier.model';
import { SnackBarService } from 'src/app/services/system/snackbar.service';
import { ErrorManagementService } from 'src/app/services/system/error-management.service';
import { CustomDialogComponent } from 'src/app/shared/custom-dialog/custom-dialog.component';
import ItemAttributes from '@albatrosdeveloper/ave-models-npm/lib/schemas/item/item.entity';
import { QuestionService } from 'src/app/services/addons/question/question.service';
import { QuestionFilter, QuestionWithTotal } from 'src/app/modules/addons/questions/question.model';
import * as moment from 'moment';
import { FormAnswerQuestionComponent } from './form-answer-question/form-answer-question.component';
import { QuestionAnswerReportComponent } from './question-answer-report/question-answer-report.component';
import ItemQuestionAttributes from '@albatrosdeveloper/ave-models-npm/lib/schemas/item-question/item-question.entity';
import { getValidateSomeCompany } from 'src/app/utils/company-validator.strategy';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  displayedColumns: string[] = [
    'det',
    'status',
    'item',
    'user',
    'question',
    'answer',
    'createdAt',
    'active',
    'action'
  ];

  dataSource = new MatTableDataSource<ItemQuestionAttributes>([])
  loadData = false

  totalResults = 0
  search: string = ''
  pageSize = 100
  pageIndex = 1
  sortActive = 'code'
  sortDirection = 'asc'

  currentUser

  constructor(
    //private warehouseService: WarehouseService,
    private questionService: QuestionService,
    private snackBarService: SnackBarService,
    private errorService: ErrorManagementService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private authenticationService: AuthService,
  ) {
    this.currentUser = this.authenticationService.currentUserValue
   }

  ngOnInit(): void {
    this.getList()
  }

  applyFilter(filterValue: string) {
    this.pageIndex = 1
    this.search = filterValue
    this.getList()
  }

  changeSort(e) {
    this.sortActive = e.active
    this.sortDirection = e.direction
    this.getList()
  }

  changePage(e) {
    this.pageIndex = (e.pageIndex + 1)
    this.pageSize = e.pageSize
    this.getList()
  }

  getList(){
    let options = { cur_page: this.pageIndex, per_page: this.pageSize, search: this.search, sort: this.sortActive, sd: this.sortDirection }
    this.loadData = true

    let filter: QuestionFilter = {
      businessPartnerId: String(getValidateSomeCompany(this.currentUser.user.businessPartners)._id)
    }

    this.questionService.getListWithCount(filter, options).subscribe({
      next: (res: QuestionWithTotal[]) => {
        this.totalResults = res[0].pageInfo[0] ? res[0].pageInfo[0].total : 0
        this.dataSource.data = res[0].data
        this.loadData = false
      },
      error: (error) => { 
        this.loadData = false
        this.errorService.getErrorMessage(error)
      }
    })
  }
  
  onchangeActive(e, item: ItemQuestionAttributes) {
    let send = {
      active: e.checked ? '1' : '0'
    }

    this.questionService.update(String(item._id), send).subscribe({
      next: (res: ItemQuestionAttributes) => {
        this.snackBarService.success({ message: this.translate.instant('TRANSLATE.SUCCESS_ACTION')})
        this.cdr.detectChanges()
      },
      error: (error) => {
        e.source.checked = item.active
        this.errorService.getErrorMessage(error)
        this.cdr.detectChanges()
      }
    })
  }

  openForm(element): void {
    const dialogRef = this.dialog.open(FormAnswerQuestionComponent, {
      width: "900px",
      data: element,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === undefined || result == "Close") {
      } else {
        if (result == 'success') {
          this.getList()
        }
      }
    });
  }

  openReports(element, type): void {
    const dialogRef = this.dialog.open(QuestionAnswerReportComponent, {
      width: "900px",
      data: {questionId: element._id, type: type, qa: (type == 'question' ? element.question : element.answer), data: (type == 'question' ? element.questionReports : element.answerReports)},
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === undefined || result == "Close") {
      } else {
        if (result == 'success') {
          this.getList()
        }
      }
    });
  }

  onDelete(element) {
    let obsLoad = new BehaviorSubject(null)
    let d = {
      title: 'Eliminar',
      body: "¿Está seguro de eliminar este registro?",
      cancel: 'No',
      confirm: 'Si',
      wait: "Espere por favor...",
      obs: obsLoad
    }
    const dialogRef = this.dialog.open(CustomDialogComponent, {
      width: "400px",
      data: d,
      disableClose: true,
    });

    let subs = obsLoad.subscribe(res => {
      if (res == true) {
        this.questionService.delete(String(element._id)).subscribe({
          next: (res: ItemQuestionAttributes) => {
            obsLoad.next(false)
            this.snackBarService.success({ message: this.translate.instant('TRANSLATE.SUCCESS_ACTION')})
            this.getList()
          },
          error: (error) => {
            obsLoad.next(false)
            this.errorService.getErrorMessage(error)
          }
        })
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      subs.unsubscribe()
    });
  }

  getDate(date: Date) {
    return moment(date).format("YYYY-MM-DD")
  }

  getHour(date: Date) {
    return moment(date).format("HH:mm:ss")
  }

  isActiveQAs(element: ItemQuestionAttributes){
      
    return  ( element.questionReports !== undefined && 
              element.questionReports != null && 
              element.questionReports?.length > 0 && 
              element.questionReports.some(qr => qr.active == '1') ) || 
            ( element.answerReports !== undefined && 
              element.answerReports != null && 
              element.answerReports?.length > 0 && 
              element.answerReports.some(qr => qr.active == '1') ) 
  }
  
}