import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'MENUITEMS.HOME.DASHBOARD',
    moduleName: 'dashboard',
    icon: 'fas fa-home',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'dashboard/main',
        title: 'Plazza',
        moduleName: 'dashboard',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
    ]
  },

  {
    path: '',
    title: 'MENUITEMS.MAIN.MODULES',
    moduleName: '',
    icon: '',
    class: '',
    groupTitle: true,
    submenu: []
  },
  /*{
    path: '',
    title: 'MENUITEMS.MAIN.MASTER',
    moduleName: 'master',
    icon: 'fas fa-tools',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: []
  },*/
  {
    path: 'master/warehouse',
    title: 'MENUITEMS.MAIN.WAREHOUSE',
    moduleName: 'master',
    icon: 'fas fa-warehouse',
    class: '', //ml-sub-sub-menu
    groupTitle: false,
    submenu: []
  },
  {
    path: 'master/item',
    title: 'MENUITEMS.MAIN.ITEM',
    moduleName: 'master',
    icon: 'fas fa-boxes',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: 'process/order',
    title: 'MENUITEMS.MAIN.ORDER',
    moduleName: 'location',
    icon: 'fas fa-shopping-cart',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: 'process/order-return',
    title: 'MENUITEMS.MAIN.ORDER_RETURN',
    moduleName: 'location',
    icon: 'fas fa-file-alt',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: 'reports/commission',
    title: 'MENUITEMS.MAIN.REPORTS',
    moduleName: 'reports',
    icon: 'fas fa-file',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: 'addons/question',
    title: 'MENUITEMS.MAIN.QUESTIONS',
    moduleName: 'setting',
    icon: 'fas fa-question',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: 'addons/promotion',
    title: 'MENUITEMS.MAIN.PROMOTIONS',
    moduleName: 'setting',
    icon: 'fas fa-tag',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: '',
    title: 'MENUITEMS.MAIN.SETTING',
    moduleName: '',
    icon: '',
    class: '',
    groupTitle: true,
    submenu: []
  },
  {
    path: 'setting/company',
    title: 'MENUITEMS.MAIN.COMPANY',
    moduleName: 'setting',
    icon: 'fas fa-building',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: 'setting/contract',
    title: 'MENUITEMS.MAIN.CONTRACT',
    moduleName: 'setting',
    icon: 'fas fa-file-contract',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: '',
    title: 'MENUITEMS.MAIN.ACCESS',
    moduleName: '',
    icon: '',
    class: '',
    groupTitle: true,
    submenu: []
  },
  {
    path: '/',
    title: 'MENUITEMS.MAIN.USERS',
    moduleName: 'access',
    icon: 'fas fa-users',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'access/user',
        title: 'MENUITEMS.MAIN.USERS',
        moduleName: 'access',
        icon: '',
        class: 'ml-sub-sub-menu',
        groupTitle: false,
        submenu: []
      },
    ]
  },
];
