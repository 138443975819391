import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Page404Component } from './authentication/page404/page404.component';
import { AuthGuard } from './core/guard/auth.guard';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/authentication/signin', pathMatch: 'full' },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule)
      },
      
      {
        path: 'master',
        loadChildren: () =>
          import('./pages/apps/modulus/master/master.module').then(
            (m) => m.MasterModule
          )
      },
      {
        path: 'addons',
        loadChildren: () =>
        import('./pages/apps/addons/addons.module').then(
            (m) => m.AddonsModule
        )
      },

      {
        path: 'process',
        loadChildren: () =>
        import('./pages/apps/modulus/process/process.module').then(
            (m) => m.ProcessModule
        )
      },
      {
        path: 'reports',
        loadChildren: () =>
        import('./pages/apps/modulus/reports/reports.module').then(
            (m) => m.ReportsModule
        )
      },
      {
        path: 'access',
        loadChildren: () =>
          import('./pages/apps/access/users/users.module').then(
            (m) => m.UsersModule
          )
      },
      {
        path: 'setting',
        loadChildren: () =>
          import('./pages/apps/setting/setting.module').then(
            (m) => m.SettingModule
          )
      },
    ]
  },
  {
    path: 'authentication',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      )
  },
  { path: '**', component: Page404Component }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
      relativeLinkResolution: 'legacy',
      initialNavigation: 'enabled'  // for one load page, without reload
      // useHash: true
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
