export const environment = {
  production: false,
  page: 'panel',
  companycode: "1",
  companyBDCode: "BP0000000001",
  domain: 'https://dgpapi.aveapplications.com/',
  domainCourier: 'https://delivery.pla22agurus.com:2083/',
  emailAPI: 'https://delivery.pla22agurus.com:2083/',
  bankTlanAPI: 'https://validate.pla22agurus.com/', //por ahora
  url_s3: 'https://plazzagurus.s3.us-east-2.amazonaws.com/folder',
  googleApiKey: 'AIzaSyCvn_zbsjzCaA6IZR7Lzww4C0E7XKM_4kE',

  ecommerceURL: 'https://qas.plazza.com',
  
  authAPI: 'auth/v1/api/auth',
  masterAPI: 'master/v1/api',
  locationAPI: 'location/v1/api',
  itemAPI: 'item/v1/api',
  warehouseAPI: 'warehouse/v1/api',
  clientAPI: 'client/v1/api',
  bankAPI: 'bank/v1/api',
  orderAPI: 'delivery/v1/api',

  comission: 15
};
