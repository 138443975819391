import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPermissionsModule } from 'ngx-permissions';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSortModule } from '@angular/material/sort';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxLoadingModule } from 'ngx-loading';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { AmazingTimePickerModule } from 'amazing-time-picker';
import { MatBadgeModule } from '@angular/material/badge';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTreeModule } from '@angular/material/tree';
import { MatChipsModule } from '@angular/material/chips';
import { MdePopoverModule } from '@material-extended/mde';

import { ErrorManagementService } from '../../../services/system/error-management.service';
import { SnackBarService } from '../../../services/system/snackbar.service';
import { QuestionComponent } from './question/question.component';
import { AddonsRoutingModule } from './addons.routing';
import { FormAnswerQuestionComponent } from './question/form-answer-question/form-answer-question.component';
import { QuestionAnswerReportComponent } from './question/question-answer-report/question-answer-report.component';
import { PromotionComponent } from './promotion/promotion.component';
import { FormPromotionComponent } from './promotion/form-promotion/form-promotion.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [
    QuestionComponent,
    FormAnswerQuestionComponent,
    QuestionAnswerReportComponent,
    PromotionComponent,
    FormPromotionComponent,
  ],
  
  entryComponents: [
    
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatSnackBarModule,
    MatButtonModule,
    MatIconModule,
    MatRadioModule,
    MatSelectModule,
    MatCheckboxModule,
    MatCardModule,
    MatDatepickerModule,
    MatDialogModule,
    MatSortModule,
    MaterialFileInputModule,
    MatTooltipModule,
    NgxLoadingModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    NgxMatSelectSearchModule,
    MatSlideToggleModule,
    AngularEditorModule,
    MatMenuModule,
    MatTabsModule,
    AmazingTimePickerModule,
    MatBadgeModule,
    DragDropModule,
    MatTreeModule,
    MatChipsModule,
    MdePopoverModule,
    MatDatepickerModule,
    TranslateModule.forChild(),
    NgxPermissionsModule.forChild(),
    AddonsRoutingModule,
    FlexLayoutModule,
  ],
  providers: [
    ErrorManagementService,
    SnackBarService,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'YYYY-MM-DD',
        },
        display: {
          dateInput: 'YYYY-MM-DD',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    },
  ]
})
export class AddonsModule { }
