<section class="content">
    <div class="container-fluid">
        <br>
        <div class="row clearfix">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="card">
                    <div *ngIf="loadData">
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    </div>
                    <div class="header d-flex justify-content-between">
                        <div class="p-2"></div>
                        <div style="display: flex;">
                            <form id="demo-2">
                                <input class="input_search" type="search" (keyup.enter)="applyFilter($event.target.value)"
                                    placeholder="{{'TRANSLATE.SEARCH' | translate}}" matTooltip="{{'TRANSLATE.SEARCH_INITIAL' | translate}}">
                            </form>
                            <button mat-icon-button color="primary" matTooltip="{{'TRANSLATE.CAPTION' | translate}}" [matMenuTriggerFor]="infoMenu">
                                <mat-icon>info_outline</mat-icon>
                            </button>
                            <mat-menu #infoMenu="matMenu">
                                <div style="max-width : 300px; padding: 0px 5px;">
                                    <div fxLayoutAlign="start center">
                                        <mat-icon style="cursor: pointer; color:rgb(40, 101, 141); vertical-align: middle;"> person</mat-icon>
                                        <span>{{'TRANSLATE.BUSINESS' | translate}}</span>
                                    </div>
                                    <div fxLayoutAlign="start center">
                                        <mat-icon style="cursor: pointer; color:#FFBF00; vertical-align: middle;">people</mat-icon>
                                        <span>{{'TRANSLATE.COMPANY' | translate}}</span>
                                    </div>                
                                </div>
                            </mat-menu>
                            <button mat-icon-button color="primary" (click)="openForm(null)" matTooltip="{{'TRANSLATE.NEW' | translate}}">
                                <mat-icon aria-label="label">add</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="body table-responsive">
                        <table mat-table [dataSource]="dataSource" class="w-100" matSort 
                            (matSortChange)="changeSort($event)" matSortDisableClear multiTemplateDataRows>

                            <!--<ng-container matColumnDef="det">
                                <th mat-header-cell *matHeaderCellDef class="pr-0 pl-0"></th>
                                <td mat-cell *matCellDef="let element" class="pr-0 pl-0" style="width: 1px">
                                  <button mat-icon-button color="primary" (click)="expandedElement = expandedElement === element ? null : element; this.openDetail(element)">
                                    <i *ngIf="expandedElement !== element" matTooltip="{{'TRANSLATE.DETAIL_ON'| translate}}" class="fas fa-chevron-right"></i>
                                    <i *ngIf="expandedElement === element" matTooltip="{{'TRANSLATE.DETAIL_OFF' | translate}}" class="fas fa-chevron-down"></i>
                                  </button>
                                </td>
                            </ng-container>-->
            
                            <ng-container matColumnDef="type">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'TRANSLATE.TYPE' | translate}}</th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-icon *ngIf="element.type == 'E'" style="color: #008000;" matTooltip="{{'TRANSLATE.EXTERN'| translate}}">account_balance</mat-icon>
                                    <mat-icon *ngIf="element.type == 'BP'" style="color:#28658d;" matTooltip="{{'TRANSLATE.BUSINESS'| translate}}">person</mat-icon>
                                    <mat-icon *ngIf="element.type == 'C'" style="color:#FFBF00;" matTooltip="{{'TRANSLATE.COMPANY'| translate}}">people</mat-icon>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'TRANSLATE.NAME' | translate}}</th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.name}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="dateStart">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'TRANSLATE.START_DATE' | translate}}</th>
                                <td mat-cell *matCellDef="let element">
                                    <span class="text">{{getDate(element.dateStart)}} {{getHour(element.dateStart)}}</span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="dateEnd">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'TRANSLATE.END_DATE' | translate}}</th>
                                <td mat-cell *matCellDef="let element">
                                    <span class="text">{{getDate(element.dateEnd)}} {{getHour(element.dateEnd)}}</span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef style="text-align: center;">{{'TRANSLATE.STATUS' | translate}}</th>
                                <td mat-cell *matCellDef="let element" style="position: relative; width: 320px;">
                                    <div fxLayout="row" fxLayoutAlign="center center">
                                        <span *ngIf="element.status == '0'" class="status-content text-status" (click)="updateStatus(element, '1')"><mat-icon class="icon-content">check</mat-icon>Aprobar</span>
                                        <span *ngIf="element.status == '0'" class="status-content text-status" (click)="updateStatus(element, '2')"><mat-icon class="icon-content">cancel</mat-icon>Desaprobar</span>
                                        <span *ngIf="element.status == '1'" class="status-content chip-content approved">Aprobado</span>
                                        <span *ngIf="element.status == '2'" class="status-content chip-content denied">Desaprobado</span>
                                        <span *ngIf="element.status == '0'" class="status-content chip-content pending">Pendiente</span>
                                        <span *ngIf="element.status == '3'" class="status-content chip-content finalized">Finalizado</span>
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="active">
                                <th mat-header-cell *matHeaderCellDef style="text-align: center;">{{'TRANSLATE.IS_ACTIVE' | translate}}</th>
                                <td mat-cell *matCellDef="let element" style="text-align: center;">
                                  <mat-slide-toggle matTooltip="{{element.active == '1' ? 'Activo': 'Inactivo'}}" [disabled]="element.type == 'C'"
                                    [checked]="element.active == '1'" (change)="onchangeActive($event , element)">
                                  </mat-slide-toggle>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element">
                                        <button mat-icon-button color="primary" [matMenuTriggerFor]="menu"
                                            matTooltip="{{'TRANSLATE.ACTIONS' | translate}}">
                                            <mat-icon>more_vert</mat-icon>
                                        </button>
                                    <mat-menu #menu="matMenu">
                                            <button mat-menu-item (click)="openForm(element)">
                                                <mat-icon color="primary">edit</mat-icon>
                                                <span>{{'TRANSLATE.EDIT' | translate}}</span>
                                            </button>
                                            <button mat-menu-item (click)="onDelete(element)">
                                                <mat-icon color="warn">delete</mat-icon>
                                                <span>{{'TRANSLATE.DELETE' | translate}}</span>
                                            </button>
                                    </mat-menu>
                                </td>
                            </ng-container>

                            <!--<ng-container matColumnDef="expandedDetail">
                                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                                    <div class="table-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">                                        
                                        <div class="ml-4 w-100" *ngIf="(element.load == true || element.load=== undefined);else tabledet">
                                            <div class="load-div">
                                                <mat-spinner [diameter]="20"></mat-spinner>
                                                <span>{{'TRANSLATE.LOADING' | translate}}</span>
                                            </div>
                                        </div>
                      
                                        <ng-template #tabledet>
                                            <div class="w-100">
                                                <div class="body table-responsive">
                                                    <table mat-table [dataSource]="dataSourceDetail" class="w-100" matSort (matSortChange)="changeSort($event)" matSortDisableClear multiTemplateDataRows>
                                                        <ng-container matColumnDef="det">
                                                            <th mat-header-cell *matHeaderCellDef class="pr-0 pl-0"></th>
                                                            <td mat-cell *matCellDef="let element; let i =index" class="pr-0 pl-0" style="width: 1px">
                                                                {{i}}
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="product">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'TRANSLATE.ITEM' | translate}}</th>
                                                            <td mat-cell *matCellDef="let element">
                                                                {{element.itemId}}
                                                            </td>
                                                        </ng-container>

                                                        <ng-container matColumnDef="amountType">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'TRANSLATE.TYPE' | translate}}</th>
                                                            <td mat-cell *matCellDef="let element" class="td-content">
                                                                <b>{{element.amountType}}</b><br>
                                                            </td>
                                                        </ng-container>

                                                        <ng-container matColumnDef="amountCompanyOrExternal">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'TRANSLATE.AMOUNT_EXTERNAL' | translate}}</th>
                                                            <td mat-cell *matCellDef="let element" class="td-content">
                                                                <b>{{element.amountCompanyOrExternal}}</b><br>
                                                            </td>
                                                        </ng-container>

                                                        <ng-container matColumnDef="amountBusinessPartner">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'TRANSLATE.AMOUNT_BP' | translate}}</th>
                                                            <td mat-cell *matCellDef="let element" class="td-content">
                                                                <b>{{element.amountBusinessPartner}}</b><br>
                                                            </td>
                                                        </ng-container>

                                                        <ng-container matColumnDef="maxQuantity">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'TRANSLATE.MAX_QUANTITY' | translate}}</th>
                                                            <td mat-cell *matCellDef="let element" class="td-content">
                                                                <b>{{element.maxQuantity}}</b><br>
                                                            </td>
                                                        </ng-container>

                                                        <ng-container matColumnDef="maxAmount">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'TRANSLATE.MAX_AMOUNT' | translate}}</th>
                                                            <td mat-cell *matCellDef="let element" class="td-content">
                                                                <b>{{element.maxAmount}}</b><br>
                                                            </td>
                                                        </ng-container>

                                                        <ng-container matColumnDef="active">
                                                            <th mat-header-cell *matHeaderCellDef>{{'TRANSLATE.STATUS' | translate}}</th>
                                                            <td mat-cell *matCellDef="let element" class="td-content">
                                                              <mat-slide-toggle matTooltip="{{element.status == '1' ? 'Activo': 'Inactivo'}}"
                                                                [checked]="element.status == '1'" (change)="onchangeActive($event , element)">
                                                              </mat-slide-toggle>
                                                            </td>
                                                        </ng-container>
                                                        
                                                        <ng-container matColumnDef="action">
                                                            <th mat-header-cell *matHeaderCellDef></th>
                                                            <td mat-cell *matCellDef="let element">
                                                                    <button mat-icon-button color="primary" [matMenuTriggerFor]="menu"
                                                                        matTooltip="{{'TRANSLATE.ACTIONS' | translate}}">
                                                                        <mat-icon>more_vert</mat-icon>
                                                                    </button>
                                                                <mat-menu #menu="matMenu">
                                                                        <button mat-menu-item (click)="openForm(element)">
                                                                            <mat-icon color="primary">edit</mat-icon>
                                                                            <span>{{'TRANSLATE.EDIT' | translate}}</span>
                                                                        </button>
                                                                </mat-menu>
                                                            </td>
                                                        </ng-container>

                                                        <tr mat-header-row *matHeaderRowDef="displayedColumnsDetail"></tr>
                                                        <tr mat-row *matRowDef="let row; columns: displayedColumnsDetail;"></tr>
                                                    </table>
                                                    <div *ngIf="this.dataSourceDetail.data.length === 0" class="mat-table-message">Por favor, agregue productos a la promoción.
                                                    </div>

                                                </div>
                                            </div>
                                        </ng-template>
                                    </div>
                                </td>
                            </ng-container>-->

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            <!--[class.table-expanded-row]="expandedElement === element"></tr>
                            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="table-detail-row"></tr>-->
                        </table>
                        <div *ngIf="this.dataSource.data.length === 0" class="mat-table-message">Por favor, agregue los puntos de entrega.
                        </div>
                        <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[100, 200, 300, 400]" [length]="totalResults"
                        [showFirstLastButtons]="true" (page)="changePage($event)"></mat-paginator>
              
                        <ngx-loading [show]="loadData"></ngx-loading>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>