import DetailPromotionAttributes from "@albatrosdeveloper/ave-models-npm/lib/schemas/detailPromotion/detailPromotion.entity"
import PromotionAttributes from "@albatrosdeveloper/ave-models-npm/lib/schemas/promotion/promotion.entity"
import { Normalizers, Ops, OrderVariant, andAllWhere, buildQuery, orWhere, orderBy, page, where } from "@albatrosdeveloper/ave-utils-npm/lib/utils/query.util"
import { HttpClient, HttpHeaders } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { PromotionDetailToSave, PromotionFilter, PromotionToSave, PromotionWithTotal } from "src/app/modules/addons/promotion/promotion.model"
import { environment } from "src/environments/environment"

const URL_API = environment.domain + environment.itemAPI
const URL_S3 = environment.url_s3

@Injectable({
  providedIn: 'root'
})
export class PromotionService {

    constructor(public http: HttpClient) { }

    headers: HttpHeaders = new HttpHeaders({
        'Content-Type': 'application/json'
    })

    getListWithCount(filter: PromotionFilter, options: { cur_page?, per_page?, search?, sort?, sd?}): Observable<PromotionWithTotal[]> {
        const query = buildQuery<any>(
            where('name', Ops.like(options.search,'i')),
            andAllWhere('_deleted', false),
            andAllWhere(<any>'businessPartners._id', Ops.eq(filter.businessPartnerId, Normalizers.ObjectId)),
            orderBy(options.sort, (options.sd == 'asc') ? (OrderVariant.Asc) : (OrderVariant.Desc)),
            page(options.cur_page, options.per_page)
        )

        const url_api = URL_API + `/promotion/withCount?filter=${encodeURIComponent(JSON.stringify(query))}`
        return this.http.get<PromotionWithTotal[]>(url_api, {headers: this.headers})
    }

    create(data: PromotionToSave): Observable<PromotionAttributes> {
        const url_api = URL_API + `/promotion`
        return this.http.post<PromotionAttributes>(url_api, data, { headers: this.headers });
    }

    update(promotionId: string, data: any): Observable<PromotionAttributes> {
        const url_api = URL_API + `/promotion/${promotionId}`
        return this.http.patch<PromotionAttributes>(url_api, data, { headers: this.headers });
    }

    delete(promotionId: string): Observable<PromotionAttributes> {
        const url_api = URL_API + `/promotion/${promotionId}`
        return this.http.delete<PromotionAttributes>(url_api, { headers: this.headers });
    }

    createOneDetail(data: PromotionDetailToSave): Observable<DetailPromotionAttributes>{
        const url_api = URL_API + `/promotion/detail`
        return this.http.post<DetailPromotionAttributes>(url_api, data, { headers: this.headers });
    }

    updateOneDetail(detailId: string, data: any): Observable<DetailPromotionAttributes>{
        const url_api = URL_API + `/promotion/detail/${detailId}`
        return this.http.patch<DetailPromotionAttributes>(url_api, data, { headers: this.headers });
    }

}