import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import CategoryAttributes from '@albatrosdeveloper/ave-models-npm/lib/schemas/category/category.entity';
import { andAllWhere, buildQuery, Normalizers, Ops, orderBy, OrderVariant, orWhere, page, where, select } from '@albatrosdeveloper/ave-utils-npm/lib/utils/query.util';
import { Observable } from 'rxjs';

const URL_API = environment.domain + environment.itemAPI

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

    constructor(public http: HttpClient) { }

    headers: HttpHeaders = new HttpHeaders({
        'Content-Type': 'application/json'
    })

    getCombo(): Observable<CategoryAttributes[]> {
        const query = buildQuery<CategoryAttributes>(
            where('active', '1'),
            select(['_id', 'code', 'name', 'hasSubCategory', 'parentIdStr']),
            orderBy('code', OrderVariant.Asc),
        )

        const url_api = URL_API + `/category?filter=${encodeURIComponent(JSON.stringify(query))}`
        return this.http.get<CategoryAttributes[]>(url_api, { headers: this.headers});
    }

    getComboByCompany(companyId: string): Observable<CategoryAttributes[]> {
        const query = buildQuery<CategoryAttributes>(
            where('active', '1'),
            andAllWhere(<any>'company._id', Ops.eq(companyId, Normalizers.ObjectId)),
            select(['_id', 'code', 'name', 'hasSubCategory', 'parentIdStr']),
            orderBy('code', OrderVariant.Asc),
        )

        const url_api = URL_API + `/category?filter=${encodeURIComponent(JSON.stringify(query))}`
        return this.http.get<CategoryAttributes[]>(url_api, { headers: this.headers});
    }

    getComboByBusinessPartner(businessPartnerId: String): Observable<CategoryAttributes[]> {
        const url_api = URL_API + `/category/businessPartner?businessPartnerId=${businessPartnerId}`
        return this.http.get<CategoryAttributes[]>(url_api, { headers: this.headers});
    }
}