import ItemQuestionAttributes from "@albatrosdeveloper/ave-models-npm/lib/schemas/item-question/item-question.entity"
import { Normalizers, Ops, OrderVariant, buildQuery, orderBy, page, where } from "@albatrosdeveloper/ave-utils-npm/lib/utils/query.util"
import { HttpClient, HttpHeaders } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { QuestionFilter, QuestionWithTotal } from "src/app/modules/addons/questions/question.model"
import { environment } from "src/environments/environment"

const URL_API = environment.domain + environment.itemAPI
const URL_S3 = environment.url_s3

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

    constructor(public http: HttpClient) { }

    headers: HttpHeaders = new HttpHeaders({
        'Content-Type': 'application/json'
    })

    getListWithCount(filter: QuestionFilter, options: { cur_page?, per_page?, search?, sort?, sd?}): Observable<QuestionWithTotal[]> {
        const query = buildQuery<any>(
            where(<any>'businessPartner._id', Ops.eq(filter.businessPartnerId, Normalizers.ObjectId)),
            orderBy(options.sort, (options.sd == 'asc') ? (OrderVariant.Asc) : (OrderVariant.Desc)),
            page(options.cur_page, options.per_page)
        )

        const url_api = URL_API + `/item-question/withCount?filter=${encodeURIComponent(JSON.stringify(query))}`
        return this.http.get<QuestionWithTotal[]>(url_api, {headers: this.headers})
    }

    update(questionId: string, data: any): Observable<ItemQuestionAttributes> {
        const url_api = URL_API + `/item-question/${questionId}`
        return this.http.patch<ItemQuestionAttributes>(url_api, data);
    }

    delete(questionId: string): Observable<ItemQuestionAttributes> {
        const url_api = URL_API + `/item-question/${questionId}`
        return this.http.delete<ItemQuestionAttributes>(url_api, { headers: this.headers });
    }

    answerQuestion(questionId:string, data:any){
        const url_api = URL_API + `/item-question/${questionId}`
        return this.http.patch<any>(url_api, data);
    }
}